import React from 'react';


const RequestADemo = ({ item }) => {

	return (
		<section className="container">
			RequestADemo
		</section>
	);
}

export default RequestADemo;
